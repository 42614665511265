import axios from '../../mixins/axiosInstance.js';
import qs from 'qs';
const state = () => ({
    search: '',
    loading: true,
    usersTable: [],
    dialogAddUser:{
        status:null,
        correo:'',
        contraseña:'',
        nombre:'',
        isAdmin:null
    },
    dialogEditUser:{
        status: null,
        correo: '',
        nombre: '',
        isAdmin:'',
        id:''
    },
    dialogDeleteUser:{
        status: null,
        mail: '',
        name: '',
        id:'',
    },
    headers: [
        {
            text: "ID",
            sortable: true,
            value: "sys_usrs_id",
        },
        {
            text: "Correo",
            sortable: true,
            value: "email",
        },
        {
            text: "Nombre",
            sortable: true,
            value: "name",
        },
        {
            text: "Fecha de Creacion",
            sortable: false,
            value: "created",
        },
        {
            text: "Administrador",
            sortable: true,
            value: "isAdmin",
        },
        {
            text: "Acciones",
            sortable: true,
            value: "acciones",
        }
    ],
    editUser:{
        contraseña:"",
        valContraseña:"",
        isAdmin: '0'
    }
})

const getters = {}

const actions = {
    async AgregarUsuario({commit,state,dispatch})
    {
        if(state.dialogAddUser.correo!=''||state.dialogAddUser.contraseña!=''||state.dialogAddUser.nombre!='')
        {
            if(state.dialogAddUser.isAdmin===true)
            {
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                await axios.instance.post("/adminUser",qs.stringify({email:state.dialogAddUser.correo,name:state.dialogAddUser.nombre,password:state.dialogAddUser.contraseña}))
                commit('ActionsDialogs',{dialog:'add',value:false})
                dispatch('loadItems')
            }else{
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                await axios.instance.post("/User",qs.stringify({email:state.dialogAddUser.correo,name:state.dialogAddUser.nombre,password:state.dialogAddUser.contraseña}))
                commit('ActionsDialogs',{dialog:'add',value:false})
                dispatch('loadItems')
            }
        }else{
            alert('LLene todos los campos')
        }
        
        
    },
    async updateUser({state,dispatch,commit})
    {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        if(state.dialogEditUser.contraseña===state.dialogEditUser.valContraseña)
        {

            var response= await axios.instance.patch("/adminUser/"+state.dialogEditUser.id,qs.stringify({isAdmin:state.editUser.isAdmin,Pass:state.editUser.contraseña}))
            if(response.status===200)
            {
                alert('Usuario modificado')
                dispatch('loadItems')
                commit('ActionsDialogs',{dialog:'edit',value:false})
            }
            
        }else{
            alert('Las contraseñas no coinciden')
        }
        
        
    },
    async DeleteUser({state,commit,dispatch})
    {
        var response= await axios.instance.delete("/adminUser/"+state.dialogDeleteUser.id)
        if(response.status===200)
        {
            alert('Usuario eliminado')
            dispatch('loadItems')
        }
        commit ('ActionsDialogs',{dialog:"delete",value:false,info:{id:"",correo:"",nombre:""}})
        state.dialogDeleteUser.status=false
    },
    async loadItems({ commit }){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        var response=await axios.instance.get("/adminUser")
        var data=response.data.data
        for(let i=0;i<data.length;i++)
        {
            if(data[i].isAdmin===0)
            {
                data[i].isAdmin='No'
            }else
            {
                data[i].isAdmin='Si'
            }
        }
        commit('loadItems', data)
    }
}

const mutations = {
    changeValues(state,info)
    {
        if(info.dialog==='edit')
        {
            switch(info.id)
            {
                case 1:
                    state.editUser.contraseña=info.value
                    break
                case 2:
                    state.editUser.valContraseña=info.value
                    break
                case 3:
                    state.editUser.isAdmin=info.value
                    break
            }
        }
        if(info.dialog==='add')
        {
            switch(info.id)
            {
                case 1:
                    state.dialogAddUser.nombre=info.value
                    break
                case 2:
                    state.dialogAddUser.correo=info.value
                    break
                case 3:
                    state.dialogAddUser.contraseña=info.value
                    break
                case 4:
                    state.dialogAddUser.isAdmin=!state.dialogAddUser.isAdmin
                break
            }
        }
    },
    loadItems (state, data) {
        state.usersTable=data
        state.loading=false
    },
    setSearchterm(state, payload){
        state.search = payload
    },
    ActionsDialogs(state,info)
    {
        switch(info.dialog)
        {
            case 'edit':
                
                state.dialogEditUser.status=info.value
                
                if(info.value===false)
                {
                    state.editUser.contraseña=""
                    state.editUser.valContraseña=""
                    state.editUser.isAdmin=null
                }else{
                    state.dialogEditUser.correo=info.info.correo
                    state.dialogEditUser.nombre=info.info.nombre
                    state.dialogEditUser.id=info.info.id
                    if(info.info.isAdmin==='Si')
                    {
                        state.editUser.isAdmin='1'
                    }else
                    {
                        state.editUser.isAdmin='0'
                    }
                }
                break
            case 'delete':
                state.dialogDeleteUser.status=info.value
                state.dialogDeleteUser.id=info.info.id
                state.dialogDeleteUser.mail=info.info.correo
                state.dialogDeleteUser.name=info.info.nombre
                break
            case 'add':
                if(info.value===false)
                {
                    state.dialogAddUser.isAdmin=null
                    state.dialogAddUser.nombre=''
                    state.dialogAddUser.correo=''
                    state.dialogAddUser.contraseña=''
                }
            state.dialogAddUser.status=info.value
            
            break
        }
    },
    closeDialogs(state,info)
    {
        switch(info)
        {
           case 'edit':
               state.dialogEditUser=false
               break
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}