//import servicesdb from '../../../API/services'
//import axios from '../../mixins/axiosInstance.js';
import axios from '../../mixins/axiosInstanceV2.js';
import qs from 'qs';
const state = () => ({
    editTextAreaRules: [v => v.length <= 150 || 'Max 150 caracteres'],
    editTextAreaValue: '',
    ShowingArriveImages: [],
    ShowingReturnImages: [],
    TypeOfReport:"",
    TypeOfFile:"",
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    dialogGalery: false,
    dialogReport:false,
    tempItem: [],
    search: '',
    singleExpand: true,
    loading: true,
    services: [],
    empresas:[],
    cuentas:[],
    SerialN:[],
    Spares:[],
    SN:[],
    ACC:[],
    COM:[],
    SerialNumber:"",
    Account:"",
    Company:"",
    headers: [
        {
            text: "ID",
            sortable: true,
            value: "service_id"
        },
        {
            text: "Radio SN",
            sortable: true,
            value: "SN",
        },
        {
            text: "N°Cuenta",
            sortable: false,
            value: "account",
        },
        {
            text: "Usuario",
            sortable: true,
            value: "username",
        },
        {
            text: "N°Cliente",
            sortable: true,
            value: "client_number",
        },
        {
            text: "Empresa",
            sortable: true,
            value: "company",
        },
        
        {
            text: "N° Teléfono",
            sortable: false,
            value: "phone_number"
        },
        {
            text: "IMEI",
            sortable: false,
            value: "IMEI"
        },
        {
            text: "Modelo",
            sortable: false,
            value: "model_name"
        },
        {
            text: "Marca",
            sortable: false,
            value: "name"
        },
        {
            text: "Carrier",
            sortable: false,
            value: "carrier"
        },
        {
            text: "Cuenta hija",
            sortable: false,
            value: "child_account"
        },
        {
            text: "Cuenta padre",
            sortable: false,
            value: "parent_account"
        },
        {
            text: "N° SIM",
            sortable: false,
            value: "sim_number"
        },
        {
            text: "Dispositivo",
            sortable: false,
            value: "type"
        },
        {
            text: "Fecha de servicio",
            sortable: true,
            value: "date",
        },
        {
            text: "Acciones",
            sortable: false,
            value: "actions",
        },
        
    ],
})

const getters = {}

const actions = {
    async getSparesOfService({commit},val){
        commit('resetSpares')
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let data = await axios.instance.get("/spares?join=true&id="+val.item.service_id)
        if(data.data.data==="No registers detected")
        {
            commit('setSpares',[]) 
        }else{
            commit('setSpares',data.data.data) 
        }
        
    },
    allowAdminFunctions() {
        if(localStorage.getItem('isAdmin') === '1' || localStorage.getItem('isAdmin') === 1){
            return true
        }
        else{ return false }
    },
    async getAllServices ({ commit }) {
        let data;
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        data = await axios.instance.get("/services?join=true")
        if(data.data.data != 'No registers detected'){
            commit('setServices', data.data.data)
        }
        else{
            commit('reloadItemState', false)
        }
    },
    async getServiceByID({ commit }, payload){
        if(payload != undefined){
            let id = payload.id;
            let data;
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            data = await axios.instance.get("/services?id="+id);
            commit('SetEditTextAreaValue', data.data.data[0].comments);
            commit('SHOW_EDIT_DIALOG', data.data.data[0])
        }
    },
    async showDeleteDialog({ commit }, payload){
        if(payload != undefined){
            let id = payload.id
            let data;
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            data = await axios.instance.get("/services?join=true&id="+id);
            commit('SHOW_DELETE_DIALOG', data.data.data[0]);
        }
    },
    async getImagesByServiceID({ commit }, payload){
        if(payload != undefined){
            let id = payload.id;
            let arriveImages, returnImages;
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            arriveImages = await axios.instance.get('/imgArrive/'+id);
            returnImages = await axios.instance.get('/imgReturn/'+id);
            await commit('setShowingImages', {arrive: arriveImages.data.data, return: returnImages.data.data});
            commit('SHOW_GALERY_DIALOG',["kk"])
        }
    },
    async deleteService({ commit, dispatch }, payload){
        if(payload != undefined){
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.delete('/services/'+payload.id);
            dispatch('getAllServices');
            commit('CLOSE_DELETE_DIALOG');
        }
    },
    async setEditedValue({ commit, dispatch }, payload){
        if(payload != undefined){
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.patch("/services/"+payload.id, qs.stringify({ comments:  payload.comment }));
            dispatch('getAllServices');
            commit('CLOSE_EDIT_DIALOG');
        }
    },
    async getSN({ commit }) {
        try
        {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var radio= await axios.instance.get("/device");
            var radios=[]
            for (let i = 0; i < radio.data.data.length; i++) {
                radios.push({text:radio.data.data[i].SN,value:radio.data.data[i].SN})
            }
            commit('SET_SN',radios)
        }catch(err)
        {
            if(err)
            {
            commit('SET_SN',[])
            }
        }
      },
    async getACC({commit}){
        try
        {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var cuenta= await axios.instance.get("/accounts");
            var cuentas=[]
            for (let i = 0; i < cuenta.data.data.length; i++) {
                cuentas.push({text:cuenta.data.data[i].account_number,value:cuenta.data.data[i].account_number})
            }
            commit('SET_ACC',cuentas)
        }catch(err)
        {
            if(err)
            {
            commit('SET_ACC',[])
            }
        }
    },
    async getCOM({commit}){
        try
        {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var cliente= await axios.instance.get("/client");
            var clientes=[]
            for (let i = 0; i < cliente.data.data.length; i++) {
                clientes.push({text:cliente.data.data[i].company,value:cliente.data.data[i].client_id})
            }
            commit('SET_COM',clientes)
        }catch(err)
        {
            if(err)
            {
            commit('SET_COM',[])
            }
        }
    }
}

const mutations = {
    setSpares(state,val){
        state.Spares=val
    },
    resetSpares(state){
        state.Spares=[]
    },
    UPDATE_ACC(state,value)
    {
        state.Account=value
    },
    UPDATE_SN(state,value)
    {
        state.SerialNumber=value
    },
    UPDATE_COM(state,value)
    {
        state.Company=value
    },
    SetTypeOfReport(state,value)
    {
        state.TypeOfReport=value
    },
    SetTypeOfFile(state,value)
    {
        state.TypeOfFile=value
    },
    SET_SN(state,seriales){
        state.SN=seriales
    },
    SET_ACC(state,cuentas){
        state.ACC=cuentas
    },
    SET_COM(state,companys){
        state.COM=companys
    },
    setShowingImages(state, images){
        state.ShowingArriveImages = images.arrive
        state.ShowingReturnImages = images.return
    },
    setServices(state, services){
        state.services = services
        state.loading = false
    },
    SetEditTextAreaValue(state, comment){
        state.editTextAreaValue = comment
    },
    reloadItemState(state, status) {
        state.loading = status
    },
    SHOW_DIALOG(state) {
        state.dialog = true
    },
    CLOSE_DIALOG(state) {
        state.dialog = false
    },
    SHOW_DELETE_DIALOG(state, payload) {
        state.dialogDelete = true
        state.tempItem = payload
    },
    CLOSE_DELETE_DIALOG(state) {
        state.dialogDelete = false
    },
    SHOW_EDIT_DIALOG(state, payload) {
        state.dialogEdit = true
        state.tempItem = payload
    },
    CLOSE_EDIT_DIALOG(state) {
        state.dialogEdit = false
    },
    SHOW_GALERY_DIALOG(state, payload) {
        state.dialogGalery = true
        state.tempItem = payload
    },
    CLOSE_GALERY_DIALOG(state) {
        state.dialogGalery = false
    },
    setSearchterm(state, payload){
        state.search = payload
    },
    SHOW_REPORT_DIALOG(state){
        state.dialogReport=true

    },
    CLOSE_REPORT_DIALOG(state){
        state.SerialNumber=""
        state.Account=""
        state.TypeOfReport=""
        state.TypeOfFile=""
        state.dialogReport=false
    },
    async getSimpleReport(state){

            var dato
            switch(state.TypeOfReport)
            {
                case "Numero de Serie":
                    dato=state.SerialNumber
                break
                case "Cuenta":
                    dato=state.Account
                break
            }
            var type
            if(state.TypeOfFile==='PDF')
            {
                type='pdf'
            }else
            {
                type='csv'
            }
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var ReportByRadio= await axios.instance.post("/Report",qs.stringify({info:dato,file:state.TypeOfFile,type:state.TypeOfReport}),{responseType: 'arraybuffer'});
            var blob = new File([ReportByRadio.data], {type:"application/"+type});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "reporte."+type
            link.click()
            

    },
    async getGeneralReport(state){
            var typefile
            if(state.TypeOfFile==='PDF')
            {
                typefile='pdf'
            }else
            {
                typefile='csv'
            }
                
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var Generalreport= await axios.instance.get("/Report/"+state.Company+"/"+state.TypeOfFile ,{responseType: 'arraybuffer'});
            var blob = new File([Generalreport.data], {type:"application/"+typefile});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "reporte."+typefile
            link.click()

    },
    isAdmin()
    {
        if(localStorage.getItem("isAdmin")===0||localStorage.getItem("isAdmin")==='0')
        {
            return false
        }
        if(localStorage.getItem("isAdmin")===1||localStorage.getItem("isAdmin")==='1')
        {
            return true
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}